import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
    months,
    weekdaysArray,
    formatDate,
    getAllDaysInMonth,
    splitDaysToWeeks,
    isWeekend,
    timeSlotColumns,
    isInPast,
    isBlacklistedDay,
    formatDateWithoutWeekday,
    showTimeSlots,
} from "../utils/dateTimePickerUtils";
import { DateCube } from "../components/DateCube";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { contactStyles as styles } from "../pages/styles";
import { TimeCube } from "./TimeCube";
import InfoIcon from "./../assets/pain_contact/info.png";
import PetraIcon from "./../assets/pain_contact/frau_toepker.png";
import { ContactOption } from "../pages/ContactPage";
type DateTimePickerProps = {
    contactOption: ContactOption;
    selectedDate: number;
    selectedDay: number;
    selectedMonth: number;
    selectedYear: number;
    prevMonth: () => void;
    nextMonth: () => void;
    handleDateSelect: (date: Date) => void;
    handleTimeSelect: (time: string) => void;
    selectedTimeSlot: string;
    invalidFields: string[];
    occupiedTimeSlots: string[];
    occupiedDays: number[];
    loadingTimes: boolean;
};

export const DateTimePicker = ({
    contactOption,
    selectedDate,
    selectedDay,
    selectedMonth,
    selectedYear,
    prevMonth,
    nextMonth,
    handleDateSelect,
    handleTimeSelect,
    selectedTimeSlot,
    invalidFields,
    occupiedTimeSlots,
    occupiedDays,
    loadingTimes,
}: DateTimePickerProps) => {
    return (
        <Box>
            <Box sx={styles.titleContainer}>
                <img src={PetraIcon} alt="petra" />
                <Box>
                    <Typography sx={styles.title}>
                        Unverbindliches Beratungsgespräch
                    </Typography>
                    <Typography sx={styles.subtitle2}>
                        <strong>Petra Töpker</strong>, Teamleitung
                        MeineProstata-Serviceprogramm
                    </Typography>
                </Box>
            </Box>

            {contactOption === "scheduleCall" && (
                <Box sx={styles.timePickerContainer}>
                    <Typography sx={styles.calendarTitle}>
                        Datum und Uhrzeit wählen:
                    </Typography>
                    <Box sx={styles.dateTimeContainer}>
                        <Box sx={styles.datePicker}>
                            <Box sx={styles.monthSelector}>
                                <Button
                                    sx={styles.monthSelectionButton}
                                    onClick={prevMonth}
                                >
                                    <ArrowBackIosIcon fontSize="large" />
                                </Button>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {`${
                                        months.filter(
                                            (month) =>
                                                month.value === selectedMonth,
                                        )[0].label
                                    } ${selectedYear}`}
                                </Typography>
                                <Button
                                    sx={styles.monthSelectionButton}
                                    onClick={nextMonth}
                                >
                                    <ArrowForwardIosIcon fontSize="large" />
                                </Button>
                            </Box>
                            <Box>
                                <Box sx={styles.datePickerRow}>
                                    {weekdaysArray.map((day) => (
                                        <Typography
                                            key={day}
                                            sx={styles.weekday}
                                        >
                                            {day}
                                        </Typography>
                                    ))}
                                </Box>
                                <Box>
                                    {splitDaysToWeeks(
                                        getAllDaysInMonth(
                                            selectedMonth,
                                            selectedYear,
                                        ),
                                    ).map((week, index) => {
                                        return (
                                            <Box
                                                key={index}
                                                sx={styles.datePickerRow}
                                            >
                                                {week.map((day, index) => (
                                                    <DateCube
                                                        day={day}
                                                        occupiedDays={
                                                            occupiedDays
                                                        }
                                                        key={index}
                                                        isAvailable={
                                                            !isWeekend(day) &&
                                                            !isInPast(day) &&
                                                            !isBlacklistedDay(
                                                                day,
                                                                occupiedDays,
                                                            )
                                                        }
                                                        isSelected={
                                                            day &&
                                                            day.getDate() ===
                                                                selectedDate
                                                        }
                                                        onDateSelect={
                                                            handleDateSelect
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        );
                                    })}
                                    {invalidFields.includes("selectedDate") && (
                                        <Typography sx={styles.error}>
                                            Bitte Wählen Sie ein Datum aus.
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={styles.timePicker}>
                            <Box sx={styles.monthSelector}>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {formatDate(
                                        selectedDay,
                                        selectedDate,
                                        selectedMonth,
                                        selectedYear,
                                    )}
                                </Typography>
                            </Box>
                            {showTimeSlots(
                                selectedDay,
                                selectedDate,
                                selectedMonth,
                                selectedYear,
                                occupiedDays,
                            ) ? (
                                <Box sx={styles.timePickerRows}>
                                    {loadingTimes && <CircularProgress />}
                                    {!loadingTimes &&
                                        timeSlotColumns.map((col, index) => (
                                            <Box
                                                sx={styles.timePickerColumn}
                                                key={index}
                                            >
                                                {col.map((time) => (
                                                    <TimeCube
                                                        key={time}
                                                        time={time}
                                                        isAvailable={
                                                            !occupiedTimeSlots.includes(
                                                                time,
                                                            )
                                                        }
                                                        isSelected={
                                                            time ===
                                                            selectedTimeSlot
                                                        }
                                                        onTimeSelect={
                                                            handleTimeSelect
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        ))}
                                </Box>
                            ) : (
                                <Box sx={styles.timePickerRows}>
                                    An diesem Tag können wir leider keine
                                    Gesprächstermine anbieten.
                                </Box>
                            )}
                            {invalidFields.includes("selectedTimeSlot") && (
                                <Typography sx={styles.error}>
                                    Bitte wählen Sie eine Uhrzeit für das
                                    Beratungsgespräch aus.
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

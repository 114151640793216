export const getCookie = (name: string) => {
    const cookies = document.cookie.split(";");
    let yummyCookie = "";
    cookies.forEach((cookie) => {
        const cookieParts = cookie.split("=");
        const cookieValue = cookieParts[1];
        if (name === cookieParts[0].trim()) {
            yummyCookie = cookieValue;
        }
    });

    return yummyCookie;
};

import {
    Box,
    Typography,
    Checkbox,
    Button,
    CircularProgress,
} from "@mui/material";

type AgreeTermsSectionProps = {
    agreeTerms: boolean;
    agreeAdditionalInformation: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: () => void;
    loading: boolean;
};

export const AgreeTermsSection = ({
    agreeTerms,
    agreeAdditionalInformation,
    handleChange,
    handleSubmit,
    loading,
}: AgreeTermsSectionProps) => {
    const styles = {
        checkboxRow: {
            display: "flex",
            alignItems: "flex-start",
        },
        checkboxRowCentered: {
            display: "flex",
            alignItems: "center",
        },
        checkbox: {
            marginRight: { xs: "1rem" },
            padding: 0,
        },
        checkboxText: {
            fontSize: { xs: ".8rem", md: "1rem" },
        },
        checkboxTextDisabled: {
            fontSize: { xs: ".8rem", md: "1rem" },
        },
        link: {
            fontWeight: 700,
            color: "rgb(71, 132, 185)",
            textDecoration: "none",
            fontSize: { xs: ".9rem", md: "1.1rem" },
        },
        anchor: {
            fontWeight: 700,
            color: "rgb(71, 132, 185)",
            textDecoration: "none",
            fontSize: "1rem",
        },
        submitButton: {
            // padding: "1rem 2rem",
            paddingBlock: ".75rem",
            color: "#fff",
            backgroundColor: "rgb(0,60,113)",
            border: "2px solid rgb(0,60,113)",
            borderRadius: "4px",
            maxWidth: "200px",
            fontWeight: 700,
            textTransform: "none",
            fontSize: { xs: ".9rem", md: "1.1rem" },
            "&:hover": {
                color: "rgb(0,60,113)",
            },
        },
    };

    // const canSubmit = () => {
    //     if (agreeTerms) return true;
    //     if (!agreeNewsletter && !agreeResults) return false;

    //     return true;
    // };
    return (
        <>
            <Box sx={styles.checkboxRowCentered}>
                <Checkbox
                    name="agreeAdditionalInformation"
                    value={agreeAdditionalInformation}
                    onChange={handleChange}
                    sx={styles.checkbox}
                />
                <Typography sx={styles.checkboxText}>
                    Ich möchte weitere Informationen und / oder
                    Benachrichtigungen über Veranstaltungen in meiner Region
                    erhalten.
                    <br></br>
                    HINWEIS: Wenn Sie dieses Kästchen ankreuzen, erhalten Sie
                    weitere Informationen über Männergesundheit. Daten werden
                    nur von der Synvie GmbH, einer Agentur für
                    Männnnergesundheit im Auftrag von Boston Scientific
                    verarbeitet.
                </Typography>
            </Box>
            <Box sx={styles.checkboxRowCentered}>
                <Checkbox
                    name="agreeTerms"
                    value={agreeTerms}
                    onChange={handleChange}
                    sx={styles.checkbox}
                />
                <Typography sx={styles.checkboxText}>
                    Bitte stimmen Sie mit einem Klick den{" "}
                    <a
                        href="/privacy-policy"
                        target="_blank"
                        style={styles.anchor}
                    >
                        Datenschutz-
                    </a>{" "}
                    und{" "}
                    <a
                        href="/terms-of-service"
                        target="_blank"
                        style={styles.anchor}
                    >
                        Nutzungsbedingungen
                    </a>{" "}
                    der Synvie GmbH zu.
                </Typography>
            </Box>
            <Button
                disabled={!agreeTerms}
                sx={styles.submitButton}
                onClick={handleSubmit}
            >
                {loading ? <CircularProgress /> : "Senden"}
            </Button>
        </>
    );
};

import { Box, TextField, Typography } from "@mui/material";

import { AgreeTermsSection } from "./AgreeTermsSection";
import { TextFieldWithError } from "./TextFieldWithError";
import { SummarySection } from "./SummarySection";
import { ContactOption } from "../pages/ContactPage";
type ContactViaPhoneFormProps = {
    fullName: string;
    fullNameRef: any;
    email: string;
    phone: string;
    agreeTerms: boolean;
    agreeResults: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: () => void;
    invalidFields: string[];
    loading: boolean;
    contactOption: ContactOption;
    note: string;
    selectedDate: number;
    selectedDay: number;
    selectedMonth: number;
    selectedYear: number;
    selectedTimeSlot: string;
    agreeAdditionalInformation: boolean;
};

export default function ContactViaPhoneForm({
    fullName,
    fullNameRef,
    email,
    phone,
    agreeAdditionalInformation,
    note,
    agreeTerms,
    handleChange,
    handleSubmit,
    invalidFields,
    loading,
    contactOption,
    selectedDate,
    selectedDay,
    selectedMonth,
    selectedYear,
    selectedTimeSlot,
}: ContactViaPhoneFormProps) {
    const styles = {
        formField: {
            flex: 1,
        },
        formContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },
        formRow: {
            flex: 1,
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
        },

        checkboxRow: {
            display: "flex",
            alignItems: "flex-start",
        },
    };
    return (
        <Box sx={styles.formContainer}>
            <Box sx={styles.formRow}>
                {fullNameRef && (
                    <TextFieldWithError
                        value={fullName}
                        inputRef={fullNameRef}
                        handleChange={handleChange}
                        name="fullName"
                        label="Vorname, Nachname"
                        size="small"
                        errorMsg={
                            invalidFields.includes("fullName")
                                ? "Bitte geben Sie Ihren vollständigen Namen an.\nVerwenden Sie ausschließlich Buchstaben."
                                : undefined
                        }
                    />
                )}
                <TextFieldWithError
                    value={phone}
                    handleChange={handleChange}
                    name="phone"
                    label="Telefon"
                    size="small"
                    errorMsg={
                        invalidFields.includes("phone")
                            ? "Bitte geben Sie Ihre Telefonnummer an.\nVerwenden Sie ausschließlich Ziffern."
                            : undefined
                    }
                />
                <TextFieldWithError
                    value={email}
                    handleChange={handleChange}
                    name="email"
                    label="E-Mail"
                    size="small"
                    errorMsg={
                        invalidFields.includes("email")
                            ? "Bitte geben Sie eine gültige E-Mail-Adresse an."
                            : undefined
                    }
                />
            </Box>
            <Box sx={styles.formRow}>
                <TextField
                    sx={styles.formField}
                    value={note}
                    onChange={handleChange}
                    name="note"
                    label="Notiz"
                    multiline
                    rows={2.5}
                />
            </Box>
            <SummarySection
                contactOption={contactOption}
                selectedDate={selectedDate}
                selectedDay={selectedDay}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                selectedTimeSlot={selectedTimeSlot}
            />

            <AgreeTermsSection
                handleChange={handleChange}
                agreeTerms={agreeTerms}
                handleSubmit={handleSubmit}
                loading={loading}
                agreeAdditionalInformation={agreeAdditionalInformation}
            />
        </Box>
    );
}

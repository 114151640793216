import React from "react";
import styles from "./ThankYou.module.css";
import { formatPhoneNumber } from "../utils/formatters";

export default function ThankYou() {
    return (
        <div className={styles.root}>
            <h3>VIELEN DANK FÜR IHRE KONTAKTAUFNAHME</h3>
            <div className={styles.section}></div>

            <div className={styles.section}>
                <p className={styles.bold}>Mo - Fr von 10 bis 16 Uhr</p>
                <p className={styles.bold}>
                    kostenfrei:{" "}
                    <a href={`tel:${process.env.REACT_APP_CLIENT_PHONE}`}>
                        {formatPhoneNumber(process.env.REACT_APP_CLIENT_PHONE)}
                    </a>
                </p>
                <p className={styles.bold}>
                    <a
                        href={`mailto:${process.env.REACT_APP_CLIENT_EMAIL}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {process.env.REACT_APP_CLIENT_EMAIL}
                    </a>
                </p>
            </div>

            <div className={styles.section}>
                <p>Mit freundlichen Grüßen</p>
                <p>Ihr</p>
                <p>Team für Männergesundheit</p>
            </div>
        </div>
    );
}

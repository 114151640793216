import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { ContactPage } from "./pages/ContactPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import ThankYouPage from "./pages/ThankYouPage";
import ConfirmRequestCancellation from "./pages/ConfirmRequestCancellationPage";

const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ContactPage />} />
                <Route path="/thank-you-created" element={<ThankYouPage />} />

                <Route
                    path="/confirm-request-cancellation"
                    element={<ConfirmRequestCancellation />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route
                    path="/terms-of-service"
                    element={<TermsOfServicePage />}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;

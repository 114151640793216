import {
    Box,
    Typography,
    Checkbox,
    Button,
    CircularProgress,
} from "@mui/material";
import { contactStyles } from "../pages/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CaldendarIcon from "./../assets/pain_contact/calendar.png";
import ClockIcon from "./../assets/pain_contact/clock.png";
import GlobeIcon from "./../assets/pain_contact/globe.png";
import InfoIcon from "./../assets/pain_contact/info.png";
import PhoneIcon from "./../assets/pain_contact/phone.png";
import { formatDate, formatTimeSlot } from "../utils/dateTimePickerUtils";
type SummarySectionProps = {
    contactOption: "immediateCall" | "scheduleCall" | "sendEmail";
    selectedDay: number;
    selectedDate: number;
    selectedMonth: number;
    selectedYear: number;
    selectedTimeSlot: string;
};

export const SummarySection = ({
    contactOption,
    selectedDay,
    selectedDate,
    selectedMonth,
    selectedYear,
    selectedTimeSlot,
}: SummarySectionProps) => {
    const styles = {
        centeredFlexBox: {
            display: "flex",
            alignItems: "center",
        },
        icon: {
            width: { xs: "20px", md: "30px" },
            height: { xs: "20px", md: "30px" },
            marginRight: ".5rem",
        },
        summaryText: {
            fontWeight: 700,
            fontSize: { xs: ".8rem", md: "1rem" },
        },
    };
    const contactDate = formatDate(
        selectedDay,
        selectedDate,
        selectedMonth,
        selectedYear,
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                mb: "1rem",
            }}
        >
            <Typography
                sx={{ ...contactStyles.subtitle, marginBlock: 0, mb: "1rem" }}
            >
                Zusammenfassung:
            </Typography>
            {contactOption === "sendEmail" && (
                <Box sx={styles.centeredFlexBox}>
                    <MailOutlineIcon
                        sx={{ width: "30px", height: "30px", mr: "1rem" }}
                    />
                    <Typography sx={{ fontWeight: 700 }}>
                        Sie bevorzugen es per Email kontaktiert zu werden.
                    </Typography>
                </Box>
            )}
            {contactOption === "scheduleCall" &&
                (selectedTimeSlot ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: { xs: "1rem", md: "2rem" },
                        }}
                    >
                        <Box sx={styles.centeredFlexBox}>
                            <Box
                                component="img"
                                src={ClockIcon}
                                sx={styles.icon}
                            />
                            <Typography sx={styles.summaryText}>
                                30min
                            </Typography>
                        </Box>
                        <Box sx={styles.centeredFlexBox}>
                            <Box
                                component="img"
                                src={PhoneIcon}
                                sx={styles.icon}
                            />
                            <Typography sx={styles.summaryText}>
                                Anruf
                            </Typography>
                        </Box>
                        <Box sx={styles.centeredFlexBox}>
                            <Box
                                component="img"
                                src={CaldendarIcon}
                                sx={styles.icon}
                            />
                            <Typography sx={styles.summaryText}>
                                {`${formatTimeSlot(
                                    selectedTimeSlot,
                                )}, ${contactDate}`}
                            </Typography>
                        </Box>
                        <Box sx={styles.centeredFlexBox}>
                            <Box
                                component="img"
                                src={GlobeIcon}
                                sx={styles.icon}
                            />
                            <Typography sx={styles.summaryText}>
                                Mitteleuropäische Zeit
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography sx={styles.summaryText}>
                        Bitte wählen Sie Ihre erwünschte Anrufzeit aus.
                    </Typography>
                ))}
        </Box>
    );
};

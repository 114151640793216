import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { formatPhoneNumber } from "../utils/formatters";

import { contactStyles as styles } from "./styles";

import InfoIcon from "./../assets/pain_contact/info.png";

import { DateTimePicker } from "../components/DateTimePicker";
import ContactViaEmailForm from "../components/ContactViaEmailForm";
import ContactViaPhoneForm from "../components/ContactViaPhoneForm";
import { validateEmailForm, validatePhoneForm } from "../utils/validateUtils";
import {
    fetchCsrfTokenAPI,
    getOccupiedDaysAPI,
    getOccupiedTimeSlotsAPI,
    submitContactViaEmailFormAPI,
    submitContactViaPhoneFormAPI,
} from "../api/contact.api";
import {
    getNextAvailableDate,
    parseDateForBackend,
} from "../utils/dateTimePickerUtils";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../utils/cookieUtils";

export type ContactOption = "immediateCall" | "scheduleCall" | "sendEmail";
declare global {
    interface Window {
        dataLayer: any[];
    }
}

export const ContactPage: React.FC = () => {
    const inputFullNameRef = React.useRef<HTMLInputElement>(null);
    const initRender = React.useRef(true);
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTimes, setLoadingTimes] = useState<boolean>(false);
    const [contactOption, setContactOption] =
        useState<ContactOption>("scheduleCall");
    const [occupiedTimeSlots, setOccupiedTimeSlots] = useState<string[]>([]);

    const [occupiedDays, setOccupiedDays] = useState<any>([]);
    const [selectedYear, setSelectedYear] = useState<number>(
        new Date().getFullYear(),
    );
    const [selectedMonth, setSelectedMonth] = useState<number>(
        getNextAvailableDate(occupiedDays)[2],
    );
    const [selectedDate, setSelectedDate] = useState<number>(
        getNextAvailableDate(occupiedDays)[0],
    );
    const [selectedDay, setSelectedDay] = useState<number>(
        getNextAvailableDate(occupiedDays)[1],
    );
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [emailConfirm, setEmailConfirm] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [note, setNote] = useState<string>("");
    const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
    const [agreeAdditionalInformation, setAgreeAdditionalinformation] =
        useState<boolean>(false);
    const [agreeResults, setAgreeResults] = useState<boolean>(true);
    const [agreeNewsletter, setAgreeNewsletter] = useState<boolean>(false);
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    const nextMonth = () => {
        if (selectedMonth === 11) {
            setSelectedYear((prev) => prev + 1);
            setSelectedMonth(0);
            return;
        }
        setSelectedMonth((prev) => prev + 1);
        setSelectedDate(1);
    };
    const prevMonth = () => {
        if (selectedMonth === 0) {
            setSelectedYear((prev) => prev - 1);
            setSelectedMonth(11);
            return;
        }
        setSelectedMonth((prev) => prev - 1);
        setSelectedDate(1);
    };

    const selectContactOption = (option: ContactOption) => {
        setContactOption(option);
        initRender.current = false;

        setAgreeTerms(false);
        if (option === "immediateCall") {
            window.location.href = `tel:${process.env.REACT_APP_CLIENT_PHONE}`;
        }
    };

    useEffect(() => {
        if (
            (contactOption === "scheduleCall" ||
                contactOption === "sendEmail") &&
            !initRender.current
        ) {
            if (inputFullNameRef.current)
                inputFullNameRef.current.focus({ preventScroll: true });
        }
    }, [contactOption]);

    const handleDateSelect = (day: Date) => {
        if (!day) return;
        setSelectedDate(day.getDate());
        setSelectedDay(day.getDay());
        setSelectedTimeSlot("");
    };
    const handleTimeSelect = (time: string) => {
        setInvalidFields((prev) =>
            prev.filter((field) => field !== "selectedTimeSlot"),
        );
        setSelectedTimeSlot(time);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInvalidFields((prev) =>
            prev.filter((field) => field !== e.target.name),
        );
        if (e.target.name === "fullName") setFullName(e.target.value);
        if (e.target.name === "email") setEmail(e.target.value);
        if (e.target.name === "emailConfirm") setEmailConfirm(e.target.value);
        if (e.target.name === "phone") setPhone(e.target.value);
        if (e.target.name === "note") setNote(e.target.value);
        if (e.target.name === "agreeTerms") setAgreeTerms(e.target.checked);
        if (e.target.name === "agreeAdditionalInformation")
            setAgreeAdditionalinformation(e.target.checked);
    };

    const phoneFormIsValid = () => {
        const invalid = validatePhoneForm(
            fullName,
            email,
            phone,
            selectedDate,
            selectedTimeSlot,
        );
        if (invalid.length === 0) return true;
        setInvalidFields(invalid);
        return false;
    };
    const emailFormIsValid = () => {
        const invalid = validateEmailForm(
            fullName,
            email,
            emailConfirm,
            phone,
            note,
            selectedDate,
            selectedTimeSlot,
        );
        if (invalid.length === 0) return true;
        setInvalidFields(invalid);
        return false;
    };

    const handleSubmit = async () => {
        if (contactOption === "scheduleCall" && phoneFormIsValid()) {
            setLoading(true);
            try {
                const res = await submitContactViaPhoneFormAPI({
                    fullName,
                    email,
                    phone,
                    note,
                    date: parseDateForBackend(
                        selectedDate,
                        selectedMonth,
                        selectedYear,
                    ),
                    timeSlot: selectedTimeSlot,
                    agreeAdditionalInformation: agreeAdditionalInformation,
                });
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
                navigate("/thank-you-created");
                window.dataLayer.push({
                    event: "interactionEvent",
                    eventAction: "form event | submission",
                    eventCategory: "meineprostata.com | all pages",
                    eventLabel: "Hotline DE",
                });
                console.log("window.dataLayer", window.dataLayer);
            }
            return;
        }
        if (contactOption === "sendEmail" && emailFormIsValid()) {
            setLoading(true);
            try {
                const res = await submitContactViaEmailFormAPI({
                    fullName,
                    email,
                    phone,
                    note,
                    date: null,
                    timeSlot: null,
                    agreeAdditionalInformation: agreeAdditionalInformation,
                });
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
                window.dataLayer.push({
                    event: "interactionEvent",
                    eventAction: "form event | submission",
                    eventCategory: "meineprostata.com | all pages",
                    eventLabel: "Hotline DE",
                });
                console.log("window.dataLayer", window.dataLayer);
                navigate("/thank-you-created");
            }
            return;
        }
    };
    useEffect(() => {
        if (!window.dataLayer) window.dataLayer = [];

        const getOccupiedDays = async () => {
            try {
                setLoadingTimes(true);
                const response = await getOccupiedDaysAPI();
                setOccupiedDays(response.detail || []);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingTimes(false);
            }
        };
        const getOccupiedTimeSlots = async () => {
            if (contactOption !== "scheduleCall") return;
            try {
                setLoadingTimes(true);
                const response = await getOccupiedTimeSlotsAPI({
                    selectedDate,
                    selectedMonth,
                    selectedYear,
                });
                setOccupiedTimeSlots(response.detail || []);
            } catch (error) {
                console.error(error);
            } finally {
                setTimeout(() => {
                    setLoadingTimes(false);
                }, 300);
            }
        };
        getOccupiedTimeSlots();
        getOccupiedDays();
    }, [contactOption, selectedDate, selectedMonth, selectedYear]);

    useEffect(() => {
        const setCsrfToken = async () => {
            try {
                await fetchCsrfTokenAPI();
            } catch (error) {
                console.error(error);
            }
        };
        setCsrfToken();
    }, []);
    return (
        <Box sx={styles.container}>
            {process.env.REACT_APP_IS_TEST === "true" && (
                <Typography>
                    Test-Server Test-Server Test-Server Test-Server Test-Server
                    Test-Server Test-Server Test-Server Test-Server Test-Server{" "}
                </Typography>
            )}
            <Box sx={styles.titleContainer}>
                <img src={InfoIcon} alt="info" />
                <Typography sx={styles.title}>
                    Ich möchte mehr über die Therapieoptionen bei benigner
                    Prostatavergrößerung erfahren.
                </Typography>
            </Box>

            <Typography sx={styles.subtitle}>
                Wie möchten Sie mit uns Kontakt aufnehmen?
            </Typography>
            <Box sx={styles.buttonBox}>
                <Button
                    sx={
                        contactOption === "immediateCall"
                            ? styles.contactOptionButtonSelected
                            : styles.contactOptionButton
                    }
                    onClick={() => selectContactOption("immediateCall")}
                >
                    Direkt anrufen (
                    {formatPhoneNumber(`${process.env.REACT_APP_CLIENT_PHONE}`)}
                    )
                </Button>

                <Button
                    sx={
                        contactOption === "scheduleCall"
                            ? styles.contactOptionButtonSelected
                            : styles.contactOptionButton
                    }
                    onClick={() => selectContactOption("scheduleCall")}
                >
                    Telefontermin vereinbaren
                </Button>
                <Button
                    sx={
                        contactOption === "sendEmail"
                            ? styles.contactOptionButtonSelected
                            : styles.contactOptionButton
                    }
                    onClick={() => selectContactOption("sendEmail")}
                >
                    E-Mail
                </Button>
            </Box>
            {contactOption === "scheduleCall" && (
                <>
                    <DateTimePicker
                        selectedDate={selectedDate}
                        selectedDay={selectedDay}
                        selectedMonth={selectedMonth}
                        selectedTimeSlot={selectedTimeSlot}
                        selectedYear={selectedYear}
                        contactOption={contactOption}
                        handleDateSelect={handleDateSelect}
                        handleTimeSelect={handleTimeSelect}
                        invalidFields={invalidFields}
                        occupiedTimeSlots={occupiedTimeSlots}
                        occupiedDays={occupiedDays}
                        loadingTimes={loadingTimes}
                        nextMonth={nextMonth}
                        prevMonth={prevMonth}
                    />
                    <Typography sx={styles.calendarTitle}>
                        Geben Sie bitte an, wie wir Sie erreichen können.
                    </Typography>
                    <ContactViaPhoneForm
                        email={email}
                        phone={phone}
                        fullName={fullName}
                        agreeTerms={agreeTerms}
                        agreeResults={agreeResults}
                        handleChange={handleChange}
                        note={note}
                        handleSubmit={handleSubmit}
                        invalidFields={invalidFields}
                        loading={loading}
                        contactOption={contactOption}
                        selectedDate={selectedDate}
                        selectedDay={selectedDay}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        selectedTimeSlot={selectedTimeSlot}
                        fullNameRef={inputFullNameRef}
                        agreeAdditionalInformation={agreeAdditionalInformation}
                    />
                </>
            )}
            {contactOption === "sendEmail" && (
                <ContactViaEmailForm
                    fullName={fullName}
                    email={email}
                    emailConfirm={emailConfirm}
                    phone={phone}
                    agreeTerms={agreeTerms}
                    agreeResults={agreeResults}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    invalidFields={invalidFields}
                    loading={loading}
                    contactOption={contactOption}
                    fullNameRef={inputFullNameRef}
                    agreeAdditionalInformation={agreeAdditionalInformation}
                />
            )}

            <Typography sx={{ mt: "1rem" }} variant="caption">
                *Der Inhalt der auf der Webseite{" "}
                <a
                    href="https://www.meineprostata.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    www.meineprostata.com
                </a>{" "}
                angebotenen Tests (IPPS und QoL) dient nur zu
                Informationszwecken und ist nicht für Produktwerbung oder
                medizinische Zwecke bestimmt. Die darin enthaltenen
                Informationen stellen keine medizinische Beratung dar. Die
                Synvie GmbH übernimmt keine Haftung für die Vollständigkeit,
                Richtigkeit oder Aktualität dieser Informationen. Die Synvie
                GmbH empfiehlt, dass Sie sich mit all Ihren Fragen rund um Ihre
                Gesundheit an Ihren Arzt wenden.
            </Typography>
        </Box>
    );
};

import axios from "axios";
import { getCookie } from "../utils/cookieUtils";
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const axiosInstance = axios.create({
    withCredentials: true,
    headers: {
        "x-csrftoken": getCookie("csrftoken"),
        "Content-Type": "application/json",
    },
});

export const submitContactViaPhoneFormAPI = async (data) => {
    const url = data.questionnaire
        ? `${BASE_API_URL}/contact/questionnaire/phone/`
        : `${BASE_API_URL}/contact/form/phone/`;
    const res = await axiosInstance.post(url, data);
    // window.dataLayer.push({
    //     event: "interactionEvent",
    //     eventAction: "form event | submission",
    //     eventCategory: "schmerz-spezialisten.de | all pages",
    //     eventLabel: "Service form",
    // });
    return res;
};

export const submitContactViaEmailFormAPI = async (data) => {
    const url = data.questionnaire
        ? `${BASE_API_URL}/contact/questionnaire/email/`
        : `${BASE_API_URL}/contact/form/email/`;
    const res = await axiosInstance.post(url, data, {
        headers: { "Content-Type": "application/json" },
    });
    // window.dataLayer.push({
    //     event: "interactionEvent",
    //     eventAction: "form event | submission",
    //     eventCategory: "schmerz-spezialisten.de | all pages",
    //     eventLabel: "Service form",
    // });
    return res;
};

export const getOccupiedTimeSlotsAPI = async ({
    selectedDate,
    selectedMonth,
    selectedYear,
}) => {
    const { data } = await axiosInstance.post(
        `${BASE_API_URL}/contact/occupied-slots/`,
        {
            date: {
                year: selectedYear,
                month: selectedMonth + 1,
                date: selectedDate,
            },
        },
    );
    return data;
};

export const getOccupiedDaysAPI = async () => {
    const ensureCsrfCookie = getCookie("csrftoken");
    const { data } = await axiosInstance.get(
        `${BASE_API_URL}/contact/occupied-days`,
    );
    return data;
};

export const fetchCsrfTokenAPI = async () => {
    const { data } = await axiosInstance.get(`${BASE_API_URL}/csrf-token`);
    return data;
};

export const validateEmail = (emailAdress) => {
    let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
        return true;
    } else {
        return false;
    }
};
export const validateEmailForm = (
    fullName,
    email,
    emailConfirm,
    phone,
    note,
    selectedDate,
    selectedTimeSlot,
) => {
    let invalidFields = [];
    if (!fullName) {
        invalidFields.push("fullName");
    }
    // if (!phone) {
    //     invalidFields.push("phone");
    // }
    if (!validateEmail(email)) {
        invalidFields.push("email");
    }
    if (email !== emailConfirm || !validateEmail(emailConfirm)) {
        invalidFields.push("emailConfirm");
    }

    return invalidFields;
};
export const validatePhoneForm = (
    fullName,
    email,
    phone,
    selectedDate,
    selectedTimeSlot,
) => {
    let invalidFields = [];
    if (!fullName) {
        invalidFields.push("fullName");
    }
    if (!phone) {
        invalidFields.push("phone");
    }
    if (!validateEmail(email)) {
        invalidFields.push("email");
    }
    if (!selectedDate) {
        invalidFields.push("selectedDate");
    }
    if (!selectedTimeSlot) {
        invalidFields.push("selectedTimeSlot");
    }
    return invalidFields;
};

// alert("Bitte wählen Sie eine Uhrzeit für das Beratungsgespräch aus.");
// alert(
//             "Bitte geben Sie Ihre Telefonnummer an.\nVerwenden Sie ausschließlich Ziffern.",
//         );

//         alert("Bitte wählen Sie ein Datum aus.");

//         alert("Bitte geben Sie eine gültige E-Mail-Adresse an.");

import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AppRouter from "./AppRouter";

const App: React.FC = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppRouter />
        </LocalizationProvider>
    );
};

export default App;

import React from "react";
import styles from "./legal.module.css";

export default function TermsOfService() {
    return (
        <div className={styles.root}>
            <h1>Nutzungsbedingungen</h1>
            <div className={styles.section}>
                <p className={styles.text__privacy}>
                    Willkommen bei Synvie GmbH Web (die Website).
                </p>
                <p className={styles.text__privacy}>
                    Bitte lesen Sie diese Nutzungsbedingungen (AGB) sorgfältig
                    durch. Für die Zwecke dieser Bedingungen wird Synvie GmbH
                    als SYNVIE, wir, wir, etc. bezeichnet. Durch die Nutzung
                    dieser Website erklären Sie sich mit diesen Bedingungen
                    einverstanden. Wenn Sie nicht damit einverstanden sind, alle
                    Bestimmungen dieser Bedingungen einzuhalten, dürfen Sie die
                    Website nicht nutzen oder darauf zugreifen. SYNVIE kann auch
                    Änderungen an diesen Bedingungen vornehmen, die auf dieser
                    Seite veröffentlicht werden und sofort nach Veröffentlichung
                    in Kraft treten. Aus diesem Grund bitten wir Sie, diese
                    Bedingungen jedes Mal zu lesen, wenn Sie unsere Website
                    besuchen. Diese Bedingungen wurden zuletzt im September 2022
                    aktualisiert.
                </p>
                <p className={styles.text__privacy}>1. Geltungsbereich</p>
                <p className={styles.text__privacy}>
                    Wir kontrollieren und betreiben diese Website von
                    Deutschland aus. Diese Website ist nur für die Nutzung durch
                    Personen mit Wohnsitz in Europa bestimmt und enthält
                    Informationen über SYNVIE-Produkte und -Dienstleistungen,
                    die zum Verkauf oder Vertrieb in Europa zur Verfügung
                    stehen. Wenn Sie nicht in Europa ansässig sind, greifen Sie
                    auf diese Website ausschließlich auf eigenes Risiko zu und
                    sind für die Einhaltung der lokalen Gesetze und Vorschriften
                    verantwortlich, falls zutreffend. Jegliche Verweise auf
                    Produkte oder Dienstleistungen auf dieser Website bedeuten
                    nicht, dass SYNVIE beabsichtigt, diese Produkte oder
                    Dienstleistungen in Ihrem Land anzukündigen oder anzubieten.
                </p>
                <p className={styles.text__privacy}>2. Registrierte Benutzer</p>
                <p className={styles.text__privacy}>
                    Besucher der Website können die öffentlichen Bereiche der
                    Website zu Informationszwecken nutzen und unterliegen den
                    vorliegenden Bedingungen. Darüber hinaus enthalten bestimmte
                    Bereiche dieser Website Informationen, die nur für
                    medizinisches Fachpersonal bestimmt sind. Dementsprechend
                    behält sich SYNVIE das Recht vor, den Zugang zu bestimmten
                    Bereichen der Website auf medizinische Fachkräfte zu
                    beschränken, die sich registrieren und die von uns
                    geforderten Qualifikationen erfüllen. Die von Ihnen zur
                    Verfügung gestellten persönlichen Informationen unterliegen
                    unserer Datenschutzrichtlinie, die Sie unter
                    www.synvie.de/Datenschutz finden.
                </p>
                <p className={styles.text__privacy}>
                    {" "}
                    3. Änderungen an der Website
                </p>
                <p className={styles.text__privacy}>
                    SYNVIE behält sich das Recht vor, die Website (oder Teile
                    davon) vorübergehend oder dauerhaft zu ändern oder
                    einzustellen, mit oder ohne Sie darüber zu informieren, und
                    ist nicht verpflichtet, die Website zu unterstützen oder zu
                    aktualisieren. SYNVIE ist weder Ihnen noch Dritten gegenüber
                    haftbar, wenn SYNVIE von seinem Recht Gebrauch macht, die
                    Website (oder Teile davon) zu ändern oder einzustellen.
                    Sofern nicht ausdrücklich anders angegeben, unterliegen alle
                    neuen Funktionen, die die aktuelle Website ergänzen oder
                    verbessern, diesen Bedingungen.
                </p>
                <p className={styles.text__privacy}>4. Copyright-Hinweis</p>
                <p className={styles.text__privacy}>
                    Alle auf dieser Site dargestellten Marken,
                    Dienstleistungsmarken und Logos sind eingetragene und nicht
                    eingetragene Marken von SYNVIE, seinen verbundenen
                    Unternehmen oder Tochtergesellschaften oder von Dritten, die
                    ihre Marken an SYNVIE oder eines seiner verbundenen
                    Unternehmen oder eine seiner Tochtergesellschaften
                    lizenziert haben. Darüber hinaus ist der gesamte Inhalt,
                    einschließlich, aber nicht beschränkt auf Text, Software,
                    Musik, Ton, Fotos, Videos, Grafiken oder anderes Material,
                    das auf dieser Website oder in kommerziell produzierten
                    Informationen enthalten ist, die Ihnen von SYNVIE, seinen
                    Tochtergesellschaften oder SYNVIEs Drittlizenzgebern über
                    die Website präsentiert werden (Inhalt), durch
                    Urheberrechte, Patente oder andere urheberrechtliche
                    Vereinbarungen und Gesetze geschützt, und es ist Ihnen nur
                    gestattet, den Inhalt so zu verwenden, wie von SYNVIE,
                    seinen Tochtergesellschaften oder seinen Lizenzgebern
                    ausdrücklich genehmigt. Keine der hierin enthaltenen
                    Bestimmungen überträgt Rechte, Titel oder Anteile an der
                    Website oder den Inhalten an Sie.
                </p>
                <p className={styles.text__privacy}>
                    Sie dürfen Inhalte herunterladen, ansehen, kopieren und
                    ausdrucken, sofern Sie die folgenden Bedingungen erfüllen:
                </p>
                <p className={styles.text__privacy}>
                    (a) Der Inhalt darf ausschließlich zu persönlichen,
                    informativen oder internen Geschäftszwecken verwendet
                    werden;
                </p>
                <p className={styles.text__privacy}>
                    (b) Alle Urheberrechts-, Marken- und sonstigen
                    Schutzrechtsvermerke müssen auf allen Kopien erscheinen,
                    einschließlich des Urheberrechtsvermerks am unteren Rand der
                    Seite;
                </p>
                <p className={styles.text__privacy}>
                    (c) der Inhalt darf in keiner Weise modifiziert oder
                    verändert werden; und
                </p>
                <p className={styles.text__privacy}>
                    (d) Grafiken dürfen nicht getrennt vom begleitenden Text
                    verwendet werden.
                </p>
                <p className={styles.text__privacy}>
                    Mit Ausnahme der oben genannten Bestimmungen dürfen Sie
                    Inhalte oder Informationen der Website ohne die
                    ausdrückliche Genehmigung von SYNVIE weder ganz noch
                    teilweise verwenden, herunterladen, hochladen, kopieren,
                    ausdrucken, anzeigen, vorführen, reproduzieren,
                    veröffentlichen, lizenzieren, posten, übertragen, in Frames
                    einbetten, in einem anderen Webdienst spiegeln, Meta-Tags
                    verwenden, Grafiken einbinden oder verbreiten. Anfragen für
                    eine solche Erlaubnis sollten an
                    webmaster@bostonscientific.com gerichtet werden. Alle hier
                    nicht ausdrücklich gewährten Rechte sind vorbehalten. Bitte
                    beachten Sie, dass SYNVIE seine geistigen Eigentumsrechte im
                    vollen Umfang des Gesetzes durchsetzt.
                </p>
                <p className={styles.text__privacy}>
                    Bestimmte Produkt-, Dienstleistungs- oder
                    Firmenbezeichnungen für andere Unternehmen als SYNVIE können
                    auf der Site nur zu Identifikationszwecken erwähnt werden.
                    Solche Bezeichnungen werden oft als Marken oder
                    Dienstleistungsmarken beansprucht. In allen Fällen, in denen
                    SYNVIE Kenntnis von einem Anspruch hat, erscheint die
                    Bezeichnung in Großbuchstaben oder in Versalien. Sie sollten
                    sich jedoch an die entsprechenden Unternehmen wenden, um
                    genauere Informationen über solche Bezeichnungen und deren
                    Registrierungsstatus zu erhalten.
                </p>
                <p className={styles.text__privacy}>
                    5. Keine medizinische Beratung
                </p>
                <p className={styles.text__privacy}>
                    Die Informationen auf dieser Website dienen nur zu
                    Informationszwecken und sind nicht als Ersatz für eine
                    professionelle medizinische Beratung gedacht oder empfohlen.
                    Lassen Sie sich in Bezug auf eine Erkrankung oder Behandlung
                    immer von Ihrem Arzt oder einem anderen qualifizierten
                    Gesundheitsdienstleister beraten.
                </p>
                <p className={styles.text__privacy}>
                    Wir können auch bestimmte Informationen, Nachschlagewerke
                    und Datenbanken bereitstellen, die für die Verwendung durch
                    lizenzierte medizinische Fachkräfte bestimmt sind. Diese
                    Hilfsmittel sind nicht dazu gedacht, professionellen
                    medizinischen Rat zu geben. Ärzte und andere
                    Leistungserbringer im Gesundheitswesen sollten immer ihr
                    eigenes klinisches Urteil für eine bestimmte Situation
                    abgeben.
                </p>
                <p className={styles.text__privacy}></p>6. Benutzer-Feedback
                <p className={styles.text__privacy}>
                    SYNVIE freut sich, von Besuchern zu hören und begrüßt Ihre
                    Kommentare zur Website. Bitte beachten Sie jedoch, dass
                    SYNVIE und seine Mitarbeiter keine unaufgeforderten Ideen
                    akzeptieren oder berücksichtigen, einschließlich Ideen für
                    neue Produkte oder Technologien, Prozesse, Materialien,
                    Marketingpläne oder neue Produktnamen. Bitte senden Sie
                    keine unaufgeforderten Ideen oder Originalmaterialien an
                    SYNVIE oder eine Person bei SYNVIE. Wenn Sie SYNVIE trotz
                    dieser Aufforderung Ihre Ideen und Materialien zusenden,
                    beachten Sie bitte, dass Sie SYNVIE mit der Übermittlung der
                    Informationen über diese Website kostenlos alle weltweiten
                    Rechte, Titel und Interessen an allen Urheberrechten und
                    anderen geistigen Eigentumsrechten an den von Ihnen
                    übermittelten Informationen oder Materialien abtreten. Wir
                    sind berechtigt, alle Informationen und Materialien, die Sie
                    uns über diese Website übermitteln, ohne Einschränkung und
                    ohne Sie in irgendeiner Weise zu entschädigen, zu verwenden,
                    und durch die Übermittlung solcher Informationen und
                    Materialien erklären Sie gegenüber SYNVIE, dass Sie das
                    Recht dazu haben.
                </p>
                <p className={styles.text__privacy}>
                    7. Einreichung von Lebensläufen
                </p>
                <p className={styles.text__privacy}>
                    SYNVIE kann die Online-Übermittlung von Lebensläufen als
                    Antwort auf Stellenausschreibungen auf der Website erlauben.
                    Ihre persönlichen Informationen unterliegen unserer
                    Datenschutzrichtlinie. Sie verpflichten sich, keine falschen
                    oder ungenauen biographischen Informationen zu
                    veröffentlichen. Personalvermittler, die Kandidaten
                    vertreten, dürfen keine Lebensläufe von Kandidaten als
                    Antwort auf Stellenausschreibungen auf der Website
                    versenden. Es besteht das Risiko, dass Ihr Lebenslauf
                    verloren geht oder von einem Dritten abgefangen wird, wenn
                    Sie ihn über die Website an SYNVIE senden. SYNVIE kann nicht
                    für Verluste verantwortlich gemacht werden, die durch die
                    Übermittlung oder Verwendung von Lebensläufen entstehen, die
                    über die Website erhalten wurden. Darüber hinaus kann SYNVIE
                    einen Vertrag mit einem Drittanbieter abschließen, um die
                    Seite zur Übermittlung von Lebensläufen zu hosten.
                </p>
                <p className={styles.text__privacy}>8. Links</p>
                <p className={styles.text__privacy}>
                    Die Website kann Links zu anderen von SYNVIE betriebenen
                    Websites sowie zu von Dritten betriebenen Websites
                    enthalten. Bitte lesen Sie die separaten
                    Nutzungsbedingungen, Datenschutzrichtlinien und andere
                    Regeln, die auf diesen verlinkten Seiten veröffentlicht
                    sind, bevor Sie diese nutzen. Links zu Webseiten Dritter
                    werden Ihnen lediglich als Annehmlichkeit zur Verfügung
                    gestellt, und die Aufnahme eines Links bedeutet nicht, dass
                    SYNVIE mit diesen Webseiten verbunden ist oder sie
                    befürwortet. SYNVIE prüft, überwacht oder kontrolliert die
                    Richtigkeit der auf den Websites Dritter veröffentlichten
                    Inhalte nicht und ist nicht verantwortlich für die
                    Verfügbarkeit und den Inhalt der Websites Dritter. Wenn Sie
                    sich dafür entscheiden, Produkte oder Dienstleistungen von
                    Dritten zu erwerben, besteht Ihre Beziehung direkt mit dem
                    Dritten, und SYNVIE ist nicht verantwortlich für Verluste
                    oder Schäden jeglicher Art, die Ihnen durch den Umgang mit
                    einem Dritten entstehen können. Die Websites Dritter können
                    Informationen über die Verwendung von Produkten enthalten,
                    die nicht von der U.S. Food and Drug Administration
                    genehmigt oder freigegeben wurden. SYNVIE befürwortet nicht
                    den Off-Label-Einsatz unserer Produkte.
                </p>
                <p className={styles.text__privacy}>
                    Im Allgemeinen dürfen Sie, sofern Sie keine andere
                    Vereinbarung mit Synvie getroffen haben, von Ihrer Website
                    aus einen Link oder Hyperlink zur Homepage der Website
                    einrichten, sofern folgende Bedingungen erfüllt sind:
                </p>
                <p className={styles.text__privacy}>
                    (a) Sie benachrichtigen uns per E-Mail an{" "}
                    <a href="mailto:datenschutz@synvie.de">
                        datenschutz@synvie.de
                    </a>{" "}
                    und geben dabei die URL jeder Seite an, von der aus Sie auf
                    unsere Homepage verlinken werden;
                </p>
                <p className={styles.text__privacy}>
                    (b) Sie rahmen die Website oder einen Teil der Website nicht
                    ein;
                </p>
                <p className={styles.text__privacy}>
                    (c) Sie keine Deep-Links auf die Site setzen (d. h. Sie
                    setzen keine Links auf andere Seiten als die Homepage);
                </p>
                <p className={styles.text__privacy}>
                    (d) der Link oder Hyperlink zur Website nicht in einer Weise
                    verwendet wird, die den Eindruck erweckt, dass SYNVIE Sie
                    oder Ihre Website unterstützt;
                </p>
                <p className={styles.text__privacy}>
                    (e) der Link wird mit einer reinen Textwiedergabe des SYNVIE
                    Namens und nicht mit einer Marke oder einem SYNVIE Logo
                    gekennzeichnet;
                </p>
                <p className={styles.text__privacy}>
                    (f) der Link oder Hyperlink zur Website nicht in einer Weise
                    verwendet oder präsentiert wird, die SYNVIE verunglimpft
                    oder die Qualität der Namen oder Marken von SYNVIE oder des
                    damit verbundenen Firmenwerts trübt, verwischt oder
                    verwässert; und
                </p>
                <p className={styles.text__privacy}>
                    (g) Sie erklären sich damit einverstanden, dass wir Ihr
                    Recht, einen Link oder Hyperlink auf die Website zu setzen,
                    jederzeit und ohne Angabe von Gründen kündigen können.
                </p>
                <p className={styles.text__privacy}>9. Haftungsausschluss</p>
                <p className={styles.text__privacy}>
                    DIE WEBSITE UND ALLE INHALTE, MATERIALIEN, INFORMATIONEN,
                    PRODUKTE UND DIENSTLEISTUNGEN, DIE AUF DER WEBSITE ZUR
                    VERFÜGUNG GESTELLT WERDEN, WERDEN OHNE MÄNGELGEWÄHR" UND IN
                    DER VORLIEGENDEN FORM" ANGEBOTEN. SYNVIE LEHNT AUSDRÜCKLICH
                    JEGLICHE AUSDRÜCKLICHE ODER STILLSCHWEIGENDE GEWÄHRLEISTUNG
                    AB, EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF DIE
                    STILLSCHWEIGENDE GEWÄHRLEISTUNG DER MARKTGÄNGIGKEIT, DER
                    EIGNUNG FÜR EINEN BESTIMMTEN ZWECK, DES EIGENTUMSRECHTS, DER
                    NICHTVERLETZUNG VON RECHTEN, DER SICHERHEIT UND DER
                    GENAUIGKEIT SOWIE ALLER GEWÄHRLEISTUNGEN, DIE SICH AUS DEM
                    HANDELSBRAUCH, DEM HANDELSVERLAUF ODER DEM LEISTUNGSVERLAUF
                    ERGEBEN. SYNVIE ÜBERNIMMT KEINE GARANTIE DAFÜR, DASS: (A)
                    DIE SEITE IHREN ANFORDERUNGEN ENTSPRICHT; (B) DIE SEITE
                    UNUNTERBROCHEN, RECHTZEITIG, SICHER UND FEHLERFREI ZUR
                    VERFÜGUNG STEHT; (C) DIE ERGEBNISSE, DIE DURCH DIE NUTZUNG
                    DER SEITE ODER DER ÜBER DIE SEITE ANGEBOTENEN
                    DIENSTLEISTUNGEN ERZIELT WERDEN, GENAU ODER ZUVERLÄSSIG
                    SIND; ODER (D) DIE QUALITÄT VON PRODUKTEN, DIENSTLEISTUNGEN,
                    INFORMATIONEN ODER ANDEREN MATERIALIEN, DIE SIE ÜBER DIE
                    SEITE ERHALTEN, IHREN ERWARTUNGEN ENTSPRICHT.
                </p>
                <p className={styles.text__privacy}>
                    JEGLICHE INHALTE, MATERIALIEN ODER INFORMATIONEN, DIE DURCH
                    DIE NUTZUNG DER WEBSITE HERUNTERGELADEN ODER ANDERWEITIG
                    ERHALTEN WERDEN, ERFOLGEN NACH EIGENEM ERMESSEN UND AUF
                    EIGENE GEFAHR. SYNVIE ÜBERNIMMT KEINE VERANTWORTUNG FÜR
                    SCHÄDEN AN IHREM COMPUTERSYSTEM ODER FÜR DEN VERLUST VON
                    DATEN, DIE DURCH DAS HERUNTERLADEN VON INHALTEN, MATERIALIEN
                    ODER INFORMATIONEN ENTSTEHEN.
                </p>
                <p className={styles.text__privacy}>
                    KEINE RATSCHLÄGE ODER INFORMATIONEN, OB MÜNDLICH ODER
                    SCHRIFTLICH, DIE SIE VON SYNVIE ODER ÜBER DIE WEBSITE
                    ERHALTEN, BEGRÜNDEN EINE GARANTIE, DIE NICHT AUSDRÜCKLICH
                    HIERIN ENTHALTEN IST.
                </p>
                <p className={styles.text__privacy}> 10. Wertpapiergesetze</p>
                <p className={styles.text__privacy}>
                    Diese Website kann zukunftsgerichtete Aussagen enthalten,
                    die Risiken und Ungewissheiten in Bezug auf unsere
                    Geschäftstätigkeit, Aussichten, Strategien, finanzielle
                    Lage, künftige wirtschaftliche Leistung und Nachfrage nach
                    unseren Produkten oder Dienstleistungen sowie unsere
                    Absichten, Pläne und Ziele beinhalten, die außerhalb unserer
                    Kontrolle liegen. Diese Aussagen beruhen auf einer Reihe von
                    Annahmen und Schätzungen, die mit erheblichen Unsicherheiten
                    behaftet sind, von denen sich viele unserer Kontrolle
                    entziehen. Wenn auf unserer Website Wörter wie
                    "antizipieren", "erwarten", "glauben", "schätzen",
                    "anstreben", "planen", "beabsichtigen", "werden" und
                    ähnliche Ausdrücke verwendet werden, sollen sie
                    zukunftsgerichtete Aussagen kennzeichnen, die unter die Safe
                    Harbors des Wertpapierrechts für zukunftsgerichtete Aussagen
                    fallen. Die Website und die darin enthaltenen Informationen
                    stellen weder ein Angebot noch eine Aufforderung zur Abgabe
                    eines Angebots zum Verkauf von Wertpapieren dar. Keine der
                    hierin enthaltenen Informationen ist dazu bestimmt, in eine
                    unserer Wertpapieranmeldungen oder -dokumente aufgenommen zu
                    werden, und soll auch nicht als solche betrachtet werden.
                    Wir möchten den Leser darauf hinweisen, dass die
                    tatsächlichen Ergebnisse von denen in den
                    zukunftsgerichteten Aussagen abweichen können und unter
                    anderem durch Risiken im Zusammenhang mit anderen Faktoren,
                    die in unseren Unterlagen bei der Securities and Exchange
                    Commission beschrieben sind, beeinträchtigt werden können.
                </p>
                <p className={styles.text__privacy}>11. Haftungsbeschränkung</p>
                <p className={styles.text__privacy}>
                    SYNVIE HAFTET UNTER KEINEN UMSTÄNDEN FÜR DIREKTE, INDIREKTE,
                    ZUFÄLLIGE, BESONDERE, STRAFENDE ODER FOLGESCHÄDEN ODER
                    SCHÄDEN AUS GEWINN-, EINKOMMENS-, DATEN- ODER
                    NUTZUNGSAUSFALL, DIE IHNEN ODER DRITTEN AUFGRUND IHRES
                    ZUGRIFFS AUF ODER IHRER NUTZUNG DER WEBSITE ODER DER ÜBER
                    DIE WEBSITE ANGEBOTENEN DIENSTLEISTUNGEN ENTSTEHEN, SELBST
                    WENN SYNVIE DARAUF HINGEWIESEN WURDE, DASS SOLCHE SCHÄDEN
                    WAHRSCHEINLICH ODER MÖGLICH SIND. DIESE HAFTUNGSBESCHRÄNKUNG
                    GILT UNTER ANDEREM FÜR DIE ÜBERTRAGUNG VON
                    DEAKTIVIERUNGSGERÄTEN ODER VIREN, DIE IHRE GERÄTE INFIZIEREN
                    KÖNNEN, AUSFALL VON MECHANISCHEN ODER ELEKTRONISCHEN GERÄTEN
                    ODER KOMMUNIKATIONSLEITUNGEN, TELEFON- ODER ANDEREN
                    VERBINDUNGSPROBLEMEN (Z. B. WENN SIE KEINEN ZUGANG ZU IHREM
                    INTERNET-SERVICE-PROVIDER HABEN), UNBEFUGTEM ZUGRIFF,
                    DIEBSTAHL, KÖRPERVERLETZUNG, SACHSCHÄDEN, BEDIENUNGSFEHLER,
                    STREIKS ODER ANDERE ARBEITSRECHTLICHE PROBLEME ODER HÖHERE
                    GEWALT. IN EINIGEN GERICHTSBARKEITEN IST DIE BESCHRÄNKUNG
                    ODER DER AUSSCHLUSS DER HAFTUNG NICHT ZULÄSSIG.
                    DEMENTSPRECHEND TREFFEN EINIGE DER OBEN GENANNTEN
                    EINSCHRÄNKUNGEN MÖGLICHERWEISE NICHT AUF SIE ZU.
                </p>
                <p className={styles.text__privacy}>
                    12. Kein Weiterverkauf der Website
                </p>
                <p className={styles.text__privacy}></p>Das Recht zur Nutzung
                der Website ist Ihr persönliches Recht. Sie verpflichten sich,
                die Website nicht zu reproduzieren, zu duplizieren, zu kopieren,
                zu verkaufen oder weiterzuverkaufen, ohne die ausdrückliche
                schriftliche Zustimmung von SYNVIE.
                <p className={styles.text__privacy}>13. Datenschutz</p>
                <p className={styles.text__privacy}>
                    Alle an die Website übermittelten Informationen,
                    einschließlich, aber nicht beschränkt auf persönlich
                    identifizierbare Informationen, werden in Übereinstimmung
                    mit der SYNVIE Datenschutzrichtlinie behandelt.
                </p>
                <p className={styles.text__privacy}>14. Schadloshaltung</p>
                <p className={styles.text__privacy}>
                    Sie erklären sich damit einverstanden, SYNVIE, seine
                    Muttergesellschaften, Tochtergesellschaften, verbundenen
                    Unternehmen, Aktionäre, Direktoren, leitenden Angestellten,
                    Mitarbeiter und Agenten zu entschädigen, zu verteidigen und
                    schadlos zu halten von jeglichen Ansprüchen, Forderungen,
                    Haftungen, Ausgaben oder Verlusten, einschließlich
                    angemessener Anwaltskosten, die von Dritten aufgrund oder in
                    Verbindung mit Ihrer Nutzung der Website oder Ihrem Zugriff
                    auf die Website oder Ihrem Verstoß gegen diese Bedingungen
                    geltend gemacht werden.
                </p>
                <p className={styles.text__privacy}>
                    15. Exportbeschränkungen/Rechtskonformität
                </p>
                <p className={styles.text__privacy}>
                    SYNVIE kontrolliert diese Website von Europa aus. Es ist
                    Ihnen nicht gestattet, auf die Website oder den Inhalt
                    zuzugreifen, sie herunterzuladen, zu nutzen oder zu
                    exportieren, wenn dies gegen deutsche oder europäische
                    Gesetze oder Vorschriften oder gegen andere anwendbare
                    Gesetze oder Vorschriften verstößt.
                </p>
                <p className={styles.text__privacy}>16. Allgemeines</p>
                <p className={styles.text__privacy}>
                    Sollte eine Bestimmung dieser Bedingungen aus irgendeinem
                    Grund ganz oder teilweise für ungültig, nicht durchsetzbar
                    oder nichtig befunden werden, so betrifft diese Feststellung
                    nur den Teil der Bestimmung, der für ungültig, nicht
                    durchsetzbar oder nichtig befunden wurde, und berührt den
                    Rest dieser Bestimmung in keiner Weise. Sie erklären sich
                    damit einverstanden, einem Gericht oder Schiedsrichter zu
                    gestatten, eine solche ungültige, nicht durchsetzbare oder
                    nichtige Bestimmung durch eine gültige Bestimmung zu
                    ersetzen, die der ungültigen, nicht durchsetzbaren oder
                    nichtigen Bestimmung inhaltlich so ähnlich wie möglich ist.
                    Die Untätigkeit von SYNVIE in Bezug auf einen Verstoß
                    Ihrerseits stellt keinen Verzicht auf unser Recht dar, in
                    Bezug auf spätere oder ähnliche Verstöße zu handeln.
                </p>
                <p className={styles.text__privacy}>
                    Diese Bedingungen unterliegen den Gesetzen der
                    Bundesrepublik Deutschland und werden in Übereinstimmung mit
                    diesen ausgelegt, ungeachtet der Bestimmungen zur
                    Rechtswahl. Im Falle eines Konflikts zwischen ausländischen
                    Gesetzen, Regeln und Vorschriften und denen Deutschlands
                    gelten die Gesetze, Regeln und Vorschriften Deutschlands.
                    Sie stimmen hiermit der persönlichen Zuständigkeit der
                    Landes- und Bundesgerichte in München, Deutschland, zu.
                </p>
                <p className={styles.text__privacy}>
                    SYNVIE ist berechtigt, seine Rechte und Pflichten im Rahmen
                    dieser Bedingungen abzutreten, ohne dass eine solche
                    Abtretung als Änderung der Bedingungen angesehen wird und
                    ohne dass Sie davon in Kenntnis gesetzt werden. Sie dürfen
                    diese Bedingungen nicht ohne die vorherige schriftliche
                    Zustimmung von SYNVIE abtreten.
                </p>
                <p className={styles.text__privacy}>
                    Diese Bedingungen, die Datenschutzrichtlinie, alle
                    Betriebsregeln, Richtlinien oder Verfahren, die von Zeit zu
                    Zeit von SYNVIE auf der Website veröffentlicht werden, sowie
                    alle Änderungen des Vorstehenden stellen die gesamte
                    Vereinbarung zwischen den Parteien in Bezug auf den
                    Gegenstand dieser Bedingungen dar und ersetzen alle früheren
                    Absprachen und Vereinbarungen, ob schriftlich oder mündlich,
                    in Bezug auf diesen Gegenstand. Nichts in diesen Bedingungen
                    ist so zu verstehen, dass eine der Parteien als Vertreter
                    oder Bevollmächtigter der anderen Partei oder beide Parteien
                    als Joint Ventures oder Partner für irgendeinen Zweck
                    gelten. Die Abschnitte 3, 4 (mit Ausnahme des zweiten
                    Absatzes), 5, 6 und 10 bis 19 gelten auch nach Beendigung
                    dieser Bedingungen.
                </p>
                <p className={styles.text__privacy}>17. Anerkenntnis</p>
                <p className={styles.text__privacy}>
                    Indem Sie auf diese Website zugreifen oder sich bei SYNVIE
                    registrieren, erklären Sie sich mit allen Bestimmungen und
                    Bedingungen dieser Bedingungen einverstanden. Sie
                    bestätigen, (a) dass Sie diese Bedingungen gelesen und
                    verstanden haben; und (b) dass diese Bedingungen die gleiche
                    Kraft und Wirkung haben wie ein unterzeichneter Vertrag.
                </p>
                <p className={styles.text__privacy}>18. Kontaktinformationen</p>
                <p className={styles.text__privacy}>
                    Wenn Sie Fragen zu diesen Bedingungen oder der Website
                    haben, wenden Sie sich bitte an SYNVIE unter{" "}
                    <a
                        href="mailto:datenschutz@synvie.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        datenschutz@synvie.de
                    </a>
                </p>
            </div>
        </div>
    );
}

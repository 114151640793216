import React from "react";
import styles from "./legal.module.css";

export default function PrivacyPolicy() {
    return (
        <div className={styles.root}>
            <h1>Datenschutzbestimmungen.</h1>
            <br></br>
            <div className={styles.section}>
                Synvie GmbH Website Männergesundheit Datum des Inkrafttretens:
                Juni 2024
            </div>
            <div className={styles.section}>
                <h6>Umfang der Richtlinie</h6>
                <p className={styles.text}>
                    Diese Datenschutzrichtlinie (Richtlinie) legt die
                    Datenschutzpraktiken der Synvie GmbH (Synvie) in Bezug auf
                    die Erhebung und Verwendung personenbezogener Daten im
                    Zusammenhang mit den Synvie-Websites (Sites) mit Sitz in
                    Europa, einschließlich mobiler Sites und unserer
                    Anwendungen, fest. Synvie ist bestrebt, Menschen mit
                    Respekt, Transparenz und Integrität zu behandeln; daher
                    verkaufen oder vermieten wir Ihre personenbezogenen Daten
                    nicht an Dritte. In dieser Richtlinie wird auch beschrieben,
                    welche Möglichkeiten Sie in Bezug auf die Verwendung Ihrer
                    personenbezogenen Daten durch uns haben und wie Sie auf Ihre
                    Informationen zugreifen und diese aktualisieren können. Wenn
                    wir uns auf SYNVIE beziehen, meinen wir die Synvie GmbH,
                    einschließlich unserer Tochtergesellschaften. SYNVIE hält
                    sich an bewährte Praktiken zum Schutz der Privatsphäre,
                    einschließlich der Generally Accepted Privacy Principles
                    (GAPP), und erfüllt die Anforderungen des internationalen
                    Rahmenwerks für den Transfer personenbezogener Daten.
                    Internationale Nutzer, die auf unsere Websites und
                    Anwendungen zugreifen oder SYNVIE Informationen zur
                    Verfügung stellen, verstehen und stimmen dem berechtigten
                    Interesse von SYNVIE zu, Ihre personenbezogenen Daten zu den
                    in dieser Richtlinie genannten Zwecken zu erheben, zu
                    nutzen, offenzulegen, zu speichern und in die Vereinigten
                    Staaten und andere Länder und Gebiete zu übertragen. Dies
                    schließt die Übermittlung von personenbezogenen Daten
                    zwischen SYNVIEs Dritten ein, ist aber nicht darauf
                    beschränkt. SYNVIE schützt alle EU-, englischen und
                    schweizerischen Daten, die in andere Drittländer übertragen
                    werden, indem wir Datenschutzbestimmungen in unsere Verträge
                    mit OnlineAnbietern aufnehmen oder indem wir sicherstellen,
                    dass die Anbieter die EUStandardmodellklausel der
                    Europäischen Kommission übernehmen, um einen angemessenen
                    Schutz der Daten zu gewährleisten.
                </p>
                <p>Zum Inhaltsverzeichnis</p>
                <ul>
                    <li> - Sammlung und Verwendung von Informationen</li>
                    <li> - Gemeinsame Nutzung und Weitergabe von Daten</li>
                    <li> - Cookies und ähnliche Technologien</li>
                    <li> - Beschreibung Ihrer Datenschutzrechte</li>
                    <li> - Sicherheit</li>
                    <li> - Links zu anderen Websites</li>
                    <li> - Minderjährige</li>
                    <li> - Aktualisierungen der Datenschutzrichtlinie</li>
                    <li> - Kontakt, Fragen, Kommentare, Reklamationen</li>
                </ul>
            </div>
            <div className={styles.section}>
                <h6>Sammlung und Verwendung von Informationen</h6>
                <p>
                    Personenbezogene Daten sind Daten, die dazu genutzt werden
                    können, Sie zu identifizieren oder zu kontaktieren, wie z.B.
                    Ihr Name, Ihre E-Mail-Adresse, Ihre Telefonnummer oder
                    ähnliche Informationen. SYNVIE erhebt personenbezogene
                    Daten, die Sie uns freiwillig zur Verfügung stellen, z.B.
                    bei der Anforderung von Informationen über unsere Websites,
                    bei der Einreichung von Lebensläufen, bei der Anmeldung zu
                    Online-Veranstaltungen oder bei der Registrierung auf der
                    Website. Wir bieten interaktive digitale Gesundheitstools
                    an, über die Informationen von den Nutzern gesammelt werden.
                    Wir sammeln auch Informationen über Ihre Interaktionen mit
                    unseren Websites und Anwendungen (z. B. Browsing-Verhalten).
                    Unsere Websites verfolgen automatisch bestimmte
                    Verhaltensweisen (Nutzungsdaten) während des Besuchs, wie z.
                    B. verweisende/verlassende URLs, und wir erfassen auch
                    andere grundlegende Informationen über Sie, die Sie nicht
                    direkt identifizieren, die aber mit Ihnen oder einem
                    bestimmten Gerät in Verbindung stehen können. Wir verwenden
                    diese Informationen, um mehr darüber zu erfahren, wie unsere
                    Websites und Online-Ressourcen genutzt werden, und um die
                    Website anderweitig zu verbessern und zu verwalten. Wir
                    verwenden diese Informationen auch, um Ihnen auf Ihre
                    Interessen und Vorlieben zugeschnittene Informationen zu
                    liefern, die auf Ihrer Nutzung der Website basieren. So
                    können wir beispielsweise die Internetprotokolladresse
                    erfassen, die Ihrem Computer von Ihrem
                    Internetdienstanbieter zugewiesen wurde. Diese Adresse kann
                    sich jedes Mal ändern, wenn Sie sich mit dem Internet
                    verbinden (eine "dynamische" IP-Adresse), oder sie kann
                    gleichbleiben (eine "statische" IP-Adresse). In den meisten
                    Fällen werden diese Informationen automatisch erfasst, um
                    unsere legitimen Geschäftsinteressen zu wahren. In einigen
                    Ländern sind wir verpflichtet, Sie vor der Erfassung dieser
                    Daten um Ihre Zustimmung zu bitten. In diesem Fall werden
                    Sie vor die Wahl gestellt, ob Sie die Erfassung und
                    Verwendung dieser Art von Daten zulassen möchten, was im
                    Allgemeinen in Form eines Zustimmungsbanners angezeigt wird
                    (der Ihnen die Wahl lässt, die Erfassung zu akzeptieren oder
                    abzulehnen). Wenn Sie ein registrierter Benutzer sind,
                    können wir Ihre Nutzungsdaten mit oder ohne Ihre
                    persönlichen Daten verwenden, um die Leistung der Website zu
                    messen und das Design und die Funktionalität der Website zu
                    verbessern.{" "}
                </p>
                <p>
                    Wir können auch die folgenden Daten freiwillig von Ihnen
                    erheben:{" "}
                </p>
                <ul>
                    <li>
                        {" "}
                        - Informationen über Ihr Unternehmen, wie z. B.
                        Firmenname, Unternehmensgröße, Geschäftsart oder Ihre
                        Position im Unternehmen
                    </li>
                    <li> - Medizinischer Zustand</li>
                    <li>
                        {" "}
                        - Demografische Informationen wie Alter, Ausbildung,
                        Geschlecht, Interessen und Standort
                    </li>
                    <li>
                        {" "}
                        - Kontaktinformationen wie E-Mail Adresse und
                        Telefonnummer
                    </li>
                    <li> - Empfehlungsschreiben</li>
                    <li> - Antworten auf Umfragen auf der Website</li>
                    <li> - Beiträge in Blogs und Community-Foren</li>
                    <li> - Transaktionsverlauf. </li>
                </ul>
                <p>Wir verwenden diese Informationen, um: </p>
                <ul>
                    <li>
                        {" "}
                        - Ihnen Produktaktualisierungen, Newsletter,
                        Marketingmitteilungen, angeforderte Produkt- oder
                        Serviceinformationen zu senden, von Ihnen angeforderte
                        Dienstleistungen zu erfüllen und Ihre Interessen und
                        Vorlieben zu verstehen.{" "}
                    </li>
                    Ihre Fragen zu beantworten
                    <li> - Ihr Konto zu verwalten</li>
                    <li>
                        {" "}
                        - Durchführung von Umfragen oder anderen Untersuchungen
                        und Analysen
                    </li>
                    <li>
                        {" "}
                        - Verbesserung unserer Website und Marketingmaßnahmen
                    </li>
                    <li> - Optimieren der Benutzererfahrung</li>
                    <li> - Personalisierte Inhalte anzeigen</li>
                    <li>
                        {" "}
                        - Zusendung von Werbematerial, sofern dies gesetzlich
                        zulässig ist{" "}
                    </li>
                    <li>
                        {" "}
                        - Ihnen die Möglichkeit zu geben, Ihren Lebenslauf zu
                        veröffentlichen, nach Stellenangeboten zu suchen und
                        potenzielle SYNVIE-Vertreter oder -Agenten zu
                        kontaktieren oder von ihnen kontaktiert zu werden{" "}
                    </li>
                    <li>
                        {" "}
                        - Sie über Aktualisierungen der Richtlinien zu
                        benachrichtigen
                    </li>
                </ul>
                <p style={{ textDecoration: "underline" }}>
                    Wir können von Ihnen auch die folgenden persönlichen Daten
                    über Ihre Kontakte sammeln:{" "}
                </p>
                <ul>
                    <li>
                        {" "}
                        -E-Mail Adresse, um Informationen oder Nachrichten zu
                        übermitteln
                    </li>
                    <p>
                        Wenn Sie uns personenbezogene Daten über Ihre Kontakte
                        zur Verfügung stellen, werden wir diese nur für den
                        speziellen Zweck verwenden, für den sie uns zur
                        Verfügung gestellt wurden. Wenn Sie der Meinung sind,
                        dass eine Ihrer Kontaktpersonen uns Ihre
                        personenbezogenen Daten zur Verfügung gestellt hat, und
                        Sie die Löschung beantragen möchten, kontaktieren Sie
                        uns bitte unter{" "}
                        <a
                            href="mailto:datenschutz@synvie.de"
                            target="_blank"
                            rel="noreferrer"
                        >
                            datenschutz@synvie.de
                        </a>
                        .
                    </p>
                    <p>
                        Zugelassene Mediziner müssen bei der Registrierung
                        zusätzliche personenbezogene Daten angeben (z. B. Beruf,
                        Titel, Medical ID und klinische Zugehörigkeit), wenn es
                        sich um Websites mit beschränktem Zugang handelt.
                        Zusätzlich zu den oben genannten Zwecken verwenden wir
                        diese Daten, um Ihre Registrierung zu überprüfen und zu
                        verifizieren. Über Ihre eindeutige
                        Arztidentifikationsnummer (UPIN) können wir zusätzliche
                        Informationen über Sie aus anderen Quellen erhalten. Wir
                        verwenden kleine elektronische Dateien, so genannte Web
                        Beacons (auch als Clear Gifs, Pixel Tags und
                        Single-Pixel Gifs bezeichnet), die es SYNVIE
                        ermöglichen, Informationen über Sie zu sammeln.
                    </p>
                    <p>
                        Persönliche Daten von Bewerbern werden ausschließlich
                        für den Zweck des Einstellungsverfahrens verwendet. Sie
                        werden nur gespeichert, um Ihre Bewerbung für aktuelle
                        SYNVIE-Stellen zu prüfen, oder soweit dies zur
                        Einhaltung der gesetzlichen Vorschriften erforderlich
                        ist.
                    </p>

                    <p>
                        Wir können Ihre personenbezogenen Daten verwenden, um
                        die Aktivitäten von nicht angeschlossenen Dritten zu
                        unterstützen, die vertraglich verpflichtet sind,
                        Dienstleistungen für oder im Namen von SYNVIE zu
                        erbringen (einschließlich der Pflege von
                        Computerdatenbanken, der Durchführung von
                        Marketingaktivitäten, sofern dies gesetzlich zulässig
                        ist, oder der Durchführung von Umfragen). Zum Beispiel
                        können personenbezogene Daten, die durch interaktive
                        digitale Gesundheits-Tools, die wir zur Verfügung
                        stellen, gesammelt werden, verwendet werden, um diese
                        Tools zu verbessern und die Zahlungsbeträge für Anbieter
                        festzulegen, die uns bei der Bereitstellung dieser Tools
                        unterstützen.
                    </p>
                </ul>
            </div>
            <div className={styles.section}>
                <h6>Weitergabe von Daten und Weiterübertragungen</h6>
                <p>
                    SYNVIE wird Ihre personenbezogenen Daten nicht an Dritte
                    verkaufen oder vermieten; es gibt jedoch bestimmte Umstände,
                    unter denen wir Ihre personenbezogenen Daten weitergeben
                    können, ohne Sie darüber zu informieren. Zusätzlich zu den
                    in dieser Richtlinie beschriebenen Offenlegungen und
                    vorbehaltlich des anwendbaren Rechts können wir und unsere
                    Drittparteien Ihre personenbezogenen Daten offenlegen:
                </p>
                <p>
                    An verbundene Unternehmen und Tochtergesellschaften für
                    Zwecke, die mit dieser Richtlinie übereinstimmen;
                </p>
                <p>
                    An nicht verbundene Dritte, die vertraglich verpflichtet
                    sind, Dienstleistungen für oder im Namen von SYNVIE zu
                    erbringen (z.B. um Computerdatenbanken zu pflegen,
                    Marketingaktivitäten durchzuführen oder Umfragen zu
                    erstellen);{" "}
                </p>
                <p>
                    Im Falle eines Unternehmensverkaufs, einer Fusion,
                    Umstrukturierung, Auflösung oder eines ähnlichen
                    Ereignisses;
                </p>

                <p>
                    an andere Personen, soweit dies durch geltende Gesetze oder
                    Vorschriften erlaubt ist; und
                </p>
                <p>
                    an Strafverfolgungsbehörden und -mitarbeiter, einschließlich
                    zur Erfüllung nationaler Sicherheitsanforderungen oder als
                    Teil eines rechtlichen Verfahrens, zum Schutz unseres
                    Eigentums oder zur Förderung von Ermittlungen bezüglich
                    eines Verstoßes gegen die Regeln und Richtlinien der
                    Website, eines unbefugten Zugriffs auf oder einer unbefugten
                    Nutzung der Website oder sonstiger illegaler Aktivitäten.
                </p>
                <p>
                    Wir können zusammengefasste Daten an ausgewählte Dritte
                    weitergeben. SYNVIE kann zum Beispiel zusammengefasste
                    Nutzungsdaten an Dritte weitergeben, um zu verstehen, wie
                    die Website genutzt wird, oder für Marketingzwecke. Siehe
                    auch "Cookies", unten. Aggregierte Daten identifizieren
                    keine Einzelpersonen.
                </p>
                <p>
                    Der unseren verbundenen Unternehmen, Tochtergesellschaften
                    und Dritten gewährte Zugriff ist ausschließlich auf den
                    Zweck beschränkt, für den diese Informationen bereitgestellt
                    wurden. Darüber hinaus verpflichten wir unsere verbundenen
                    Unternehmen, Tochtergesellschaften und Dritte, die
                    SYNVIE-Richtlinien zum Schutz der Privatsphäre und zur
                    Behandlung Ihrer persönlichen Daten einzuhalten. Unabhängig
                    von ihrem Standort oder den Gesetzen der Länder, in denen
                    sie ansässig sind, sind sie vertraglich verpflichtet,
                    mindestens das gleiche Schutzniveau zu bieten, wie es in den
                    Grundsätzen des Privacy Shield gefordert wird.
                </p>
            </div>
            <div className={styles.section}>
                <h6>Cookies und ähnliche Technologien</h6>
                <p>
                    SYNVIE und unsere Partner verwenden eine Vielzahl von
                    Technologien, um die Bereitstellung von Diensten für Sie
                    über unsere Websites und Anwendungen zu erleichtern und um
                    Ihre persönlichen Präferenzen zu verstehen und zu erhalten.
                    Die Verwendung dieser Technologien unterliegt unserer
                    Richtlinie oder der Datenschutzrichtlinie des Dritten, der
                    den Dienst anbietet.
                </p>
            </div>
            <div className={styles.section}>
                <h6>Zugang und Wahlmöglichkeit</h6>
                <p>
                    Registrierte Nutzer und solche, die SYNVIE personenbezogene
                    Daten zur Verfügung gestellt haben, können die Überprüfung,
                    Änderung oder Löschung von Informationen beantragen, indem
                    sie sich einloggen und ihr Profil ändern oder eine E-Mail an{" "}
                    <a
                        href="mailto:webmaster@bostonscientific.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        webmaster@bostonscientific.com
                    </a>{" "}
                    senden. Anträge auf Löschung oder Änderung sollten direkt
                    von Ihnen gestellt werden. Wir werden auf alle Anfragen
                    innerhalb von 30 Tagen antworten.
                </p>
                <p>
                    Wenn Sie nicht möchten, dass (1) die von Ihnen über die
                    Website bereitgestellten personenbezogenen Daten zu
                    Werbezwecken an Dritte weitergegeben werden, oder (2) wenn
                    Sie nicht möchten, dass Ihre personenbezogenen Daten auf
                    dieser Website für einen bestimmten Zweck verwendet werden,
                    senden Sie eine E-Mail an SYNVIE unter{" "}
                    <a
                        href="mailto:datenschutz@synvie.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        datenschutz@synvie.de
                    </a>
                    . Um den Erhalt einer bestimmten Marketing- oder
                    Werbemitteilung abzulehnen, befolgen Sie bitte die in der
                    jeweiligen Mitteilung enthaltenen Anweisungen zur Abmeldung.
                </p>
                <p>
                    Wenn Sie einen von Ihnen eingereichten Erfahrungsbericht
                    aktualisieren oder löschen möchten, kontaktieren Sie uns
                    bitte unter{" "}
                    <a
                        href="mailto:datenschutz@synvie.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        datenschutz@synvie.de
                    </a>
                    .
                </p>
                <p>
                    Die Informationen, die Sie in öffentlich zugänglichen
                    Site-Blogs oder Community-Foren veröffentlichen, können von
                    anderen, die darauf zugreifen, gelesen, gesammelt und
                    verwendet werden. Um die Löschung Ihrer persönlichen Daten
                    aus unserem Blog oder CommunityForum zu beantragen,
                    kontaktieren Sie uns unter{" "}
                    <a
                        href="mailto:datenschutz@synvie.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        datenschutz@synvie.de
                    </a>
                    . In einigen Fällen kann es sein, dass wir nicht in der Lage
                    sind, Ihre persönlichen Daten zu entfernen. In diesem Fall
                    werden wir Sie darüber informieren, wenn wir dies nicht tun
                    können und warum.
                </p>
                <p>Aufbewahrung von Daten</p>
                <p>
                    Wir bewahren Ihre Daten auf, solange Ihr Konto aktiv ist
                    oder um Ihnen Dienstleistungen anbieten zu können. Wir
                    bewahren Ihre Daten auch auf und verwenden sie, um unseren
                    rechtlichen Verpflichtungen nachzukommen, Streitigkeiten
                    beizulegen und unsere Vereinbarungen durchzusetzen.
                </p>
            </div>
            <div className={styles.section}>
                <h6>Sicherheit</h6>
                <p>
                    SYNVIE nimmt die Sicherheit ernst. Wir setzen angemessene
                    und geeignete Datenschutzmaßnahmen ein, wie z. B. robuste
                    Technologien, Sicherheitsrichtlinien und - verfahren, um das
                    Risiko des Missbrauchs, der Veränderung, der versehentlichen
                    Zerstörung oder des Verlusts sowie der unbefugten
                    Offenlegung oder des Zugriffs auf unsere Systeme und Daten
                    zu verringern. So verschlüsseln wir beispielsweise die
                    Übertragung sensibler Daten mit der Secure Socket
                    Layer-Technologie (SSL). Wir halten uns an
                    Industriestandards und bewährte Verfahren, um Ihre
                    persönlichen Daten während der Übertragung und nach dem
                    Empfang zu schützen. Leider ist keine Sicherheitsmethode zu
                    100 % sicher; daher können wir keine absolute Sicherheit
                    garantieren. Bei Fragen zur Sicherheit der Website oder der
                    Anwendung wenden Sie sich bitte per E-Mail an{" "}
                    <a
                        href="mailto:datenschutz@synvie.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        datenschutz@synvie.de
                    </a>
                    .
                </p>
            </div>
            <div className={styles.section}>
                <h6>Links zu anderen Sites</h6>
                <p>
                    Diese Richtlinie gilt nur für SYNVIE Websites und
                    Anwendungen, die auf diese Richtlinie verweisen. Unsere
                    Sites enthalten Links sowohl zu unseren angeschlossenen
                    Sites als auch zu Nicht-SYNVIE-Websites, einschließlich des
                    Zugangs zu Inhalten, Produkten und Diensten solcher
                    angeschlossenen und nicht angeschlossenen Sites (andere
                    Sites). SYNVIE ist nicht verantwortlich für die
                    Datenschutzpraktiken der anderen Websites. Sie sollten sich
                    direkt an diese anderen Websites wenden, um deren
                    Datenschutzrichtlinien zu lesen und weitere Informationen
                    über deren Praktiken zu erhalten.{" "}
                </p>
            </div>
            <div className={styles.section}>
                <h6>Minderjährige</h6>
                <p>
                    Unsere Websites und Anwendungen sind nicht für die Nutzung
                    durch Personen unter 18 Jahren bestimmt, und SYNVIE sammelt
                    nicht wissentlich personenbezogene Daten von Personen dieser
                    Altersgruppe. Wenn Sie unter 18 Jahre alt sind, stellen Sie
                    bitte die Nutzung unserer Websites und Anwendungen ein. Wenn
                    wir erfahren, dass sich eine Person unter 18 Jahren
                    registriert hat, werden wir alle damit verbundenen
                    persönlichen Daten aus unseren Aufzeichnungen löschen.
                </p>
            </div>
            <div className={styles.section}>
                <h6>Aktualisierungen der Datenschutzrichtlinie</h6>
                <p>
                    SYNVIE kann diese Richtlinie nach eigenem Ermessen
                    aktualisieren, indem es die geänderte Richtlinie auf dieser
                    Website veröffentlicht. Wir werden Sie vor den Änderungen,
                    soweit erforderlich, über Ihre Konto-E-Mail-Adresse oder
                    durch einen Hinweis auf der Website über wesentliche
                    Änderungen der Richtlinie informieren.
                </p>
                <p>
                    Diese Richtlinie wurde zuletzt am 1. Februar 2020
                    aktualisiert.
                </p>

                <p>Kontakt, Fragen, Kommentare, Beschwerden</p>
                <p>
                    Bitte richten Sie datenschutzbezogene Probleme, Fragen,
                    Kommentare oder Beschwerden an eine der folgenden Stellen
                </p>
                <p>
                    E-Mail:{" "}
                    <a
                        href="mailto:datenschutz@synvie.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        datenschutz@synvie.de
                    </a>
                </p>
                <p>Postal Mail:</p>
                <div>Synvie GmbH</div>
                <div>Agnes-Pockels-Bogen 1</div>
                <div>80992 München (D)</div>
            </div>
        </div>
    );
}
